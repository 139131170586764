import { Box } from "@mui/material";
import NotFoundImg from "assets/notfound.png";

const NotFound = () => {
  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <img width="30%" src={NotFoundImg} alt="not found" />
    </Box>
  );
};

export default NotFound;
