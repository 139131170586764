import { Action, PayloadAction } from "@reduxjs/toolkit";
import {
  ERROR_COMPANY,
  RECEIVED_COMPANY,
  REQUEST_COMPANY,
} from "./company-constants";
import { ICompanyListType } from "./company-contracts";

export const requestCompany = (): Action<typeof REQUEST_COMPANY> => {
  return {
    type: REQUEST_COMPANY,
  };
};

export const receivedCompany = (
  payload: ICompanyListType
): PayloadAction<ICompanyListType, typeof RECEIVED_COMPANY> => {
  return {
    type: RECEIVED_COMPANY,
    payload,
  };
};

export const errorCompany = (): Action<typeof ERROR_COMPANY> => {
  return {
    type: ERROR_COMPANY,
  };
};

export type CompanyAction =
  | ReturnType<typeof requestCompany>
  | ReturnType<typeof receivedCompany>
  | ReturnType<typeof errorCompany>;
