import { call, put, takeLatest } from "redux-saga/effects";
import { getCareer } from "services/CareerService";
import { errorCareer, receivedCareer } from "./career-actions";
import { REQUEST_CAREER } from "./career-constants";

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* fetchCareer(): any {
  try {
    const res = yield call(getCareer);
    yield put(receivedCareer(res.data));
  } catch (e) {
    yield put(errorCareer());
  }
}

/*
  Alternatively you may use takeLatest.

  Does not allow concurrent fetches of user. If "USER_FETCH_REQUESTED" gets
  dispatched while a fetch is already pending, that pending fetch is cancelled
  and only the latest one will be run.
*/
function* careerWatcherSaga() {
  yield takeLatest(REQUEST_CAREER, fetchCareer);
}

export default careerWatcherSaga;
