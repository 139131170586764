import { HomepageAction } from "./homepage-actions";
import {
  HOMEPAGE_NONE,
  HOMEPAGE_RECEIVED,
  HOMEPAGE_REQUESTING,
  RECEIVED_HOMEPAGE,
  REQUEST_HOMEPAGE,
} from "./homepage-constants";
import { IInitialState } from "./homepage-contracts";

const initialState: IInitialState = {
  list: null,
  status: HOMEPAGE_NONE,
};

const homepageReducer = (state = initialState, action: HomepageAction) => {
  switch (action.type) {
    case REQUEST_HOMEPAGE: {
      return {
        ...initialState,
        status: HOMEPAGE_REQUESTING,
      };
    }
    case RECEIVED_HOMEPAGE: {
      return {
        list: action.payload,
        status: HOMEPAGE_RECEIVED,
      };
    }
    default: {
      return state;
    }
  }
};

export default homepageReducer;
