import { Box, styled, Typography } from "@mui/material";
import IonIcon from "@reacticons/ionicons";
import { API_BASE_URL } from "constants/api-constants";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { IBannerType } from "redux/modules/homeModule/homepage-contracts";
import { useSwiper } from "swiper/react";
import INButton from "theme/INButton.component";

const Decription = styled("div")({
  position: "absolute",
  textAlign: "left",
});

export default function BannerDetail({
  el,
  index,
}: {
  el: IBannerType;
  index: number;
}) {
  const swiper = useSwiper();

  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname.split("/")[1] === "") {
      if (el?.banner_section === "home") {
        try {
          swiper.slideTo(index);
        } catch (err) {
          console.error(err);
        }
      }
    } else {
      if (el?.banner_section.includes(location.pathname.split("/")[1])) {
        try {
          swiper.slideTo(index);
        } catch (err) {
          console.error(err);
        }
      }
    }
  }, [el, index, location, swiper]);

  return (
    <Box
      style={{
        backgroundColor: "#f9f9f9",
      }}
      className="banner-wrapper"
      height="100%"
      position="relative"
    >
      <img
        style={{
          width: "100%",
          objectFit: "cover",
        }}
        className="banner-img"
        src={`${API_BASE_URL}uploads/banner/${el.image}`}
        alt="banner1"
      />
      <Decription className="banner-description">
        <Typography
          component="p"
          lineHeight="1.2"
          fontWeight="300"
          letterSpacing="-1px"
          dangerouslySetInnerHTML={{
            __html: el?.description as string,
          }}
        />
        <INButton onClick={() => navigate("/contacts")}>
          <Box>Lets Talk</Box>
          <Box
            display="flex"
            height="100%"
            marginTop="-6px"
            alignItems="center"
            marginLeft={1}
          >
            <IonIcon name="arrow-forward-outline" />
          </Box>
        </INButton>
      </Decription>
    </Box>
  );
}
