import { Swiper } from "swiper/react";

// Import Swiper styles
import { Autoplay, Pagination, SwiperOptions } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

interface ISwiper extends SwiperOptions {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const INCarousel = ({ children, ...props }: ISwiper) => {
  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={5}
      modules={[Pagination, Autoplay]}
      autoplay={{
        delay: 5000,
      }}
      className="mySwiper"
      lazy={true}
      {...props}
    >
      {children}
    </Swiper>
  );
};

export default INCarousel;
