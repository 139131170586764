import { Action, PayloadAction } from "@reduxjs/toolkit";
import { ERROR_WORK, RECEIVED_WORK, REQUEST_WORK } from "./work-constants";
import { IWorkListType } from "./work-contracts";

export const requestWork = (): Action<typeof REQUEST_WORK> => {
  return {
    type: REQUEST_WORK,
  };
};

export const receivedWork = (
  payload: IWorkListType
): PayloadAction<IWorkListType, typeof RECEIVED_WORK> => {
  return {
    type: RECEIVED_WORK,
    payload,
  };
};

export const errorWork = (): Action<typeof ERROR_WORK> => {
  return {
    type: ERROR_WORK,
  };
};

export type WorkAction =
  | ReturnType<typeof requestWork>
  | ReturnType<typeof receivedWork>
  | ReturnType<typeof errorWork>;
