import { Action, PayloadAction } from "@reduxjs/toolkit";
import {
  ERROR_CONTACT,
  RECEIVED_CONTACT,
  REQUEST_CONTACT,
} from "./contact-constants";
import { IContactListType } from "./contact-contracts";

export const requestContact = (): Action<typeof REQUEST_CONTACT> => {
  return {
    type: REQUEST_CONTACT,
  };
};

export const receivedContact = (
  payload: IContactListType[]
): PayloadAction<IContactListType[], typeof RECEIVED_CONTACT> => {
  return {
    type: RECEIVED_CONTACT,
    payload,
  };
};

export const errorContact = (): Action<typeof ERROR_CONTACT> => {
  return {
    type: ERROR_CONTACT,
  };
};

export type ContactAction =
  | ReturnType<typeof requestContact>
  | ReturnType<typeof receivedContact>
  | ReturnType<typeof errorContact>;
