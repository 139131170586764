import { CompanyAction } from "./company-actions";
import {
  COMPANY_NONE,
  COMPANY_RECEIVED,
  COMPANY_REQUESTING,
  RECEIVED_COMPANY,
  REQUEST_COMPANY,
} from "./company-constants";
import { IInitialState } from "./company-contracts";

const initialState: IInitialState = {
  list: null,
  status: COMPANY_NONE,
};

const CompanyReducer = (state = initialState, action: CompanyAction) => {
  switch (action.type) {
    case REQUEST_COMPANY: {
      return {
        ...initialState,
        status: COMPANY_REQUESTING,
      };
    }
    case RECEIVED_COMPANY: {
      return {
        list: action.payload,
        status: COMPANY_RECEIVED,
      };
    }
    default: {
      return state;
    }
  }
};

export default CompanyReducer;
