import { offline } from "@redux-offline/redux-offline";
import offlineConfig from "@redux-offline/redux-offline/lib/defaults";
import { Config } from "@redux-offline/redux-offline/lib/types";
import { applyMiddleware, Middleware, StoreEnhancer } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createStore, IModuleStore } from "redux-dynamic-modules";
import { getSagaExtension } from "redux-dynamic-modules-saga";
import { IState } from "./interface/IState";
import { getCareerModule } from "./modules/careerModule";
import { getCompanysModule } from "./modules/companyModule";
import { getContactsModule } from "./modules/contactModule";
import { getHomepageModule } from "./modules/homeModule";
import { getInsightsModule } from "./modules/insightsModule";
import { getSolutionModule } from "./modules/solutionModule";
import { getWorkModule } from "./modules/workModule";

const configureOffline = (offlineConfigOverrides: Partial<Config>) =>
  offline({
    ...offlineConfig,
    ...offlineConfigOverrides,
  }) as StoreEnhancer;

const middlewares: Middleware[] = [];
const middlewareEnhancer = applyMiddleware(...middlewares);

const enhancers = [middlewareEnhancer, configureOffline(offlineConfig)];

const store: IModuleStore<IState> = createStore(
  {
    initialState: {},
    enhancers,
    extensions: [getSagaExtension({} /* saga context */)],
    advancedComposeEnhancers: composeWithDevTools({
      trace: true,
    }),
  },
  getInsightsModule(),
  getHomepageModule(),
  getCompanysModule(),
  getContactsModule(),
  getWorkModule(),
  getCareerModule(),
  getSolutionModule()

  /* ...any additional modules */
);

export default store;
