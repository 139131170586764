import { Box, styled } from "@mui/material";
import { useSelector } from "react-redux";
import { selectContactList } from "redux/modules/contactModule/contact-selector";

const MapWrapper = styled(Box)({});

const Map = () => {
  const details = useSelector(selectContactList);

  return (
    <MapWrapper
      width="100%"
      style={{
        background: "rgb(218 218 218)",
      }}
      className="map-section"
      marginTop="26px"
    >
      <iframe
        src={details?.map_link}
        width="100%"
        height="100%"
        style={{
          border: 0,
        }}
        className="banner-img"
        loading="lazy"
        title="Company Location"
      />
    </MapWrapper>
  );
};

export default Map;
