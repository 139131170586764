import { SolutionAction } from "./solution-actions";
import {
  RECEIVED_SOLUTION,
  REQUEST_SOLUTION,
  SOLUTION_NONE,
  SOLUTION_RECEIVED,
  SOLUTION_REQUESTING,
} from "./solution-constants";
import { IInitialState } from "./solution-contracts";

const initialState: IInitialState = {
  list: null,
  status: SOLUTION_NONE,
};

const SolutionReducer = (state = initialState, action: SolutionAction) => {
  switch (action.type) {
    case REQUEST_SOLUTION: {
      return {
        ...initialState,
        status: SOLUTION_REQUESTING,
      };
    }
    case RECEIVED_SOLUTION: {
      return {
        list: action.payload,
        status: SOLUTION_RECEIVED,
      };
    }
    default: {
      return state;
    }
  }
};

export default SolutionReducer;
