import IonIcon from "@reacticons/ionicons";
import CompanyIcon from "theme/icons/CompanyIcon";
import CompanyIconFilled from "theme/icons/CompanyIconFilled";
import ContactIcon from "theme/icons/ContactIcon";
import ContactIconFilled from "theme/icons/ContactIconFilled";

const sidebarContent = [
  {
    label: "Home",
    icon: <IonIcon name="home-outline" />,
    activeIcon: <IonIcon name="home" />,
    route: "/",
  },

  {
    label: "Works",
    icon: <IonIcon name="shapes-outline" />,
    activeIcon: <IonIcon name="shapes" />,
    route: "/works",
  },
  {
    label: "Solutions",
    icon: <IonIcon name="bulb-outline" />,
    activeIcon: <IonIcon name="bulb" />,
    route: "/solutions",
  },
  {
    label: "Contact",
    icon: <ContactIcon />,
    activeIcon: <ContactIconFilled />,
    route: "/contacts",
  },
  // {
  //   label: "divider",
  //   icon: <></>,
  //   route: "",
  // },
  // {
  //   label: "Insights",
  //   icon: <InsightsIcon />,
  //   route: "/insights",
  // },

  {
    label: "Career",
    icon: <IonIcon name="school-outline" />,
    activeIcon: <IonIcon name="school" />,
    route: "/career",
  },
  {
    label: "Company",
    icon: <CompanyIcon />,
    activeIcon: <CompanyIconFilled />,
    route: "/company",
  },
];

export default sidebarContent;
