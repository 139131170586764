import { call, put, takeLatest } from "redux-saga/effects";
import { getHomepageService } from "services/HomepageService";
import { errorHomepage, receivedHomepage } from "./homepage-actions";
import { REQUEST_HOMEPAGE } from "./homepage-constants";

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* fetchHomepage(): any {
  try {
    const res = yield call(getHomepageService);
    yield put(receivedHomepage(res.data));
  } catch (e) {
    yield put(errorHomepage());
  }
}

/*
  Alternatively you may use takeLatest.

  Does not allow concurrent fetches of user. If "USER_FETCH_REQUESTED" gets
  dispatched while a fetch is already pending, that pending fetch is cancelled
  and only the latest one will be run.
*/
function* homepageWatcherSaga() {
  yield takeLatest(REQUEST_HOMEPAGE, fetchHomepage);
}

export default homepageWatcherSaga;
