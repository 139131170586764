import { Action, PayloadAction } from "@reduxjs/toolkit";
import {
  ERROR_CAREER,
  RECEIVED_CAREER,
  REQUEST_CAREER,
} from "./career-constants";
import { ICareerListType } from "./career-contracts";

export const requestCareer = (): Action<typeof REQUEST_CAREER> => {
  return {
    type: REQUEST_CAREER,
  };
};

export const receivedCareer = (
  payload: ICareerListType[]
): PayloadAction<ICareerListType[], typeof RECEIVED_CAREER> => {
  return {
    type: RECEIVED_CAREER,
    payload,
  };
};

export const errorCareer = (): Action<typeof ERROR_CAREER> => {
  return {
    type: ERROR_CAREER,
  };
};

export type CareerAction =
  | ReturnType<typeof requestCareer>
  | ReturnType<typeof receivedCareer>
  | ReturnType<typeof errorCareer>;
