import { WorkAction } from "./work-actions";
import {
  RECEIVED_WORK,
  REQUEST_WORK,
  WORK_NONE,
  WORK_RECEIVED,
  WORK_REQUESTING,
} from "./work-constants";
import { IInitialState } from "./work-contracts";

const initialState: IInitialState = {
  list: null,
  status: WORK_NONE,
};

const WorkReducer = (state = initialState, action: WorkAction) => {
  switch (action.type) {
    case REQUEST_WORK: {
      return {
        ...initialState,
        status: WORK_REQUESTING,
      };
    }
    case RECEIVED_WORK: {
      return {
        list: action.payload,
        status: WORK_RECEIVED,
      };
    }
    default: {
      return state;
    }
  }
};

export default WorkReducer;
