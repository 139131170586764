import { SubmitContentType } from "pages/contact/components/Form";
import httpClient from "./httpClient";

export const getContactDetails = async () => {
  return await httpClient.get("/api/contact");
};

export const sendMessage = async (data: SubmitContentType) => {
  return await httpClient.post("/api/contact-form", data);
};
