import { ISagaModule } from "redux-dynamic-modules-saga";
import { requestContact } from "./contact-actions";
import { IContactAwareState } from "./contact-contracts";
import ContactReducer from "./contact-reducers";
import careerWatcherSaga from "./contact-saga";

export function getContactsModule(): ISagaModule<IContactAwareState> {
  return {
    id: "contact-module",
    reducerMap: {
      contact: ContactReducer,
    },
    sagas: [careerWatcherSaga],
    // Actions to fire when this module is added/removed
    initialActions: [requestContact()],
    // finalActions: [],
  };
}
