import { ISagaModule } from "redux-dynamic-modules-saga";
import { requestCareer } from "./career-actions";
import CareerReducer from "./career-reducers";
import { ICareerAwareState } from "./career-contracts";
import insightWatcherSaga from "./career-saga";

export function getCareerModule(): ISagaModule<ICareerAwareState> {
  return {
    id: "career-module",
    reducerMap: {
      career: CareerReducer,
    },
    sagas: [insightWatcherSaga],
    // Actions to fire when this module is added/removed
    initialActions: [requestCareer()],
    // finalActions: [],
  };
}
