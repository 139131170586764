import ReactGA from "react-ga4";
import { Metric } from "web-vitals";

export function sendToGoogleAnalytics({ name, delta, value, id }: Metric) {
  // console.log({ name, delta, value, id });

  ReactGA.send({
    hitType: "event",
    eventCategory: "Web Vitals",
    eventAction: name,
    eventLabel: id,
    nonInteraction: true,
    // Built-in params:
    value: Math.round(name === "CLS" ? delta * 1000 : delta), // Use `delta` so the value can be summed.
    // Custom params:
    metric_id: id, // Needed to aggregate events.
    metric_value: value, // Optional.
    metric_delta: delta, // Optional.

    // OPTIONAL: any additional params or debug info here.
    // See: https://web.dev/debug-web-vitals-in-the-field/
    // metric_rating: 'good' | 'ni' | 'poor',
    // debug_info: '...',
    // ...
  });
}
