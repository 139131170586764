import { ISagaModule } from "redux-dynamic-modules-saga";
import { requestWork } from "./work-actions";
import { IWorkAwareState } from "./work-contracts";
import WorkReducer from "./work-reducers";
import workWatcherSaga from "./work-saga";

export function getWorkModule(): ISagaModule<IWorkAwareState> {
  return {
    id: "work-module",
    reducerMap: {
      work: WorkReducer,
    },
    sagas: [workWatcherSaga],
    // Actions to fire when this module is added/removed
    initialActions: [requestWork()],
    // finalActions: [],
  };
}
