import { styled } from "@mui/material";

const FooterContent = styled("div")({
  // height: " 40px",
  display: "flex",
  alignItems: "center",
  fontSize: "10px",
  // padding: "2px 13px",
  justifyContent: "center",
  paddingTop: "20px",
});

const Footer = () => {
  const current = new Date();
  const date = `${current.getFullYear()}`;

  return (
    <FooterContent className="footer">
      &#169; {date}. All Rights Reserved - Sciever Inc Pvt. Ltd.
    </FooterContent>
  );
};

export default Footer;
