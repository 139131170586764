import { ISagaModule } from "redux-dynamic-modules-saga";
import { requestCompany } from "./company-actions";
import { ICompanyAwareState } from "./company-contracts";
import CompanyReducer from "./company-reducers";
import careerWatcherSaga from "./company-saga";

export function getCompanysModule(): ISagaModule<ICompanyAwareState> {
  return {
    id: "careers-module",
    reducerMap: {
      company: CompanyReducer,
    },
    sagas: [careerWatcherSaga],
    // Actions to fire when this module is added/removed
    initialActions: [requestCompany()],
    // finalActions: [],
  };
}
