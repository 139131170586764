import { ContactAction } from "./contact-actions";
import {
  CONTACT_NONE,
  CONTACT_RECEIVED,
  CONTACT_REQUESTING,
  RECEIVED_CONTACT,
  REQUEST_CONTACT,
} from "./contact-constants";
import { IInitialState } from "./contact-contracts";

const initialState: IInitialState = {
  list: null,
  status: CONTACT_NONE,
};

const ContactReducer = (state = initialState, action: ContactAction) => {
  switch (action.type) {
    case REQUEST_CONTACT: {
      return {
        ...initialState,
        status: CONTACT_REQUESTING,
      };
    }
    case RECEIVED_CONTACT: {
      return {
        list: action.payload,
        status: CONTACT_RECEIVED,
      };
    }
    default: {
      return state;
    }
  }
};

export default ContactReducer;
