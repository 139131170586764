import { call, put, takeLatest } from "redux-saga/effects";
import { getWorkDetails } from "services/WorkService";
import { errorWork, receivedWork } from "./work-actions";
import { REQUEST_WORK } from "./work-constants";

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* fetchWork(): any {
  try {
    const res = yield call(getWorkDetails);
    yield put(receivedWork(res.data));
  } catch (e) {
    yield put(errorWork());
  }
}

/*
  Alternatively you may use takeLatest.

  Does not allow concurrent fetches of user. If "USER_FETCH_REQUESTED" gets
  dispatched while a fetch is already pending, that pending fetch is cancelled
  and only the latest one will be run.
*/
function* careerWatcherSaga() {
  yield takeLatest(REQUEST_WORK, fetchWork);
}

export default careerWatcherSaga;
