import { call, put, takeLatest } from "redux-saga/effects";
import { getSolutionDetails } from "services/SolutionService";
import { errorSolution, receivedSolution } from "./solution-actions";
import { REQUEST_SOLUTION } from "./solution-constants";

// solutioner Saga: will be fired on USER_FETCH_REQUESTED actions
function* fetchSolution(): any {
  try {
    const res = yield call(getSolutionDetails);
    yield put(receivedSolution(res.data));
  } catch (e) {
    yield put(errorSolution());
  }
}

/*
  Alternatively you may use takeLatest.

  Does not allow concurrent fetches of user. If "USER_FETCH_REQUESTED" gets
  dispatched while a fetch is already pending, that pending fetch is cancelled
  and only the latest one will be run.
*/
function* careerWatcherSaga() {
  yield takeLatest(REQUEST_SOLUTION, fetchSolution);
}

export default careerWatcherSaga;
