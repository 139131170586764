import { Action, PayloadAction } from "@reduxjs/toolkit";
import {
  ERROR_HOMEPAGE,
  RECEIVED_HOMEPAGE,
  REQUEST_HOMEPAGE,
} from "./homepage-constants";
import { IHomepageListType } from "./homepage-contracts";

export const requestHomepage = (): Action<typeof REQUEST_HOMEPAGE> => {
  return {
    type: REQUEST_HOMEPAGE,
  };
};

export const receivedHomepage = (
  payload: IHomepageListType
): PayloadAction<IHomepageListType, typeof RECEIVED_HOMEPAGE> => {
  return {
    type: RECEIVED_HOMEPAGE,
    payload,
  };
};

export const errorHomepage = (): Action<typeof ERROR_HOMEPAGE> => {
  return {
    type: ERROR_HOMEPAGE,
  };
};

export type HomepageAction =
  | ReturnType<typeof requestHomepage>
  | ReturnType<typeof receivedHomepage>
  | ReturnType<typeof errorHomepage>;
