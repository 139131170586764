import { Action, PayloadAction } from "@reduxjs/toolkit";
import {
  ERROR_INSIGHTS,
  RECEIVED_INSIGHTS,
  REQUEST_INSIGHTS,
} from "./insight-constants";
import { IInsightListType } from "./insights-contracts";

export const requestInsights = (): Action<typeof REQUEST_INSIGHTS> => {
  return {
    type: REQUEST_INSIGHTS,
  };
};

export const receivedInsights = (
  payload: IInsightListType[]
): PayloadAction<IInsightListType[], typeof RECEIVED_INSIGHTS> => {
  return {
    type: RECEIVED_INSIGHTS,
    payload,
  };
};

export const errorInsights = (): Action<typeof ERROR_INSIGHTS> => {
  return {
    type: ERROR_INSIGHTS,
  };
};

export type InsightAction =
  | ReturnType<typeof requestInsights>
  | ReturnType<typeof receivedInsights>
  | ReturnType<typeof errorInsights>;
