import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { selectContactList } from "redux/modules/contactModule/contact-selector";

const Details = () => {
  const details = useSelector(selectContactList);
  return (
    <Box
      textAlign="left"
      paddingTop="5px"
      display="flex"
      flexDirection="column"
      minHeight="180px"
      maxHeight="200px"
      className="contact_detail"
      justifyContent="center"
    >
      <Box>
        <Typography variant="body1" component="h6" fontWeight={600}>
          Sciever Inc. Pvt. Ltd.
        </Typography>
        <Typography
          color="#373737"
          variant="body2"
          component="p"
          dangerouslySetInnerHTML={{
            __html: details?.location as string,
          }}
          paddingBottom={1}
          sx={{
            "& p": {
              margin: 0,
            },
          }}
        />

        <Typography
          color="#373737"
          variant="body2"
          paddingTop={"10px"}
          fontSize="12px!important"
        >
          {details?.phone}
        </Typography>
        <Typography color="#373737" variant="body2" fontSize="12px!important">
          {details?.mail}
        </Typography>
        {/* <Typography color="#373737" variant="body2">
        {details?.website_url}
      </Typography> */}
      </Box>
    </Box>
  );
};

export default Details;
