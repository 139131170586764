import { Box } from "@mui/material";
import ScieverLogo from "theme/icons/ScieverLogo";

const Loader = () => {
  return (
    <Box
      height="100%"
      minHeight="100%"
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      // border="1px solid red"
    >
      <div className="zoom-in-out-box">
        <ScieverLogo />
      </div>
    </Box>
  );
};

export default Loader;
