import { createSelector } from "@reduxjs/toolkit";
import { IState } from "redux/interface/IState";
import { HOMEPAGE_REQUESTING } from "./homepage-constants";

const selectHomepage = (state: IState) => state?.homepage;

export const selectHomepageList = createSelector(
  selectHomepage,
  (state) => state?.list
);
export const selectcompanydesc = createSelector(
  selectHomepage,
  (state) => state?.list?.company_description?.[0]
);

export const selectTestimonial = createSelector(
  selectHomepage,
  (state) => state?.list?.testimonials
);

export const selectClients = createSelector(
  selectHomepage,
  (state) => state?.list?.our_clients
);

export const isHomepageLoading = createSelector(
  selectHomepage,
  (state) => state?.status === HOMEPAGE_REQUESTING
);
