import { ISagaModule } from "redux-dynamic-modules-saga";
import { requestInsights } from "./insight-actions";
import InsightReducer from "./insight-reducers";
import { IInsightsAwareState } from "./insights-contracts";
import insightWatcherSaga from "./insights-saga";

export function getInsightsModule(): ISagaModule<IInsightsAwareState> {
  return {
    id: "insights-module",
    reducerMap: {
      insights: InsightReducer,
    },
    sagas: [insightWatcherSaga],
    // Actions to fire when this module is added/removed
    initialActions: [requestInsights()],
    // finalActions: [],
  };
}
