import { ISagaModule } from "redux-dynamic-modules-saga";
import { requestHomepage } from "./homepage-actions";
import { IHomepageAwareState } from "./homepage-contracts";
import homepageReducer from "./homepage-reducers";
import homepageWatcherSaga from "./homepage-saga";

export function getHomepageModule(): ISagaModule<IHomepageAwareState> {
  return {
    id: "homepage-module",
    reducerMap: {
      homepage: homepageReducer,
    },
    sagas: [homepageWatcherSaga],
    // Actions to fire when this module is added/removed
    initialActions: [requestHomepage()],
    // finalActions: [],
  };
}
