import { call, put, takeLatest } from "redux-saga/effects";
import { getContactDetails } from "services/ContactService";
import { errorContact, receivedContact } from "./contact-actions";
import { REQUEST_CONTACT } from "./contact-constants";

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* fetchContact(): any {
  try {
    const res = yield call(getContactDetails);
    yield put(receivedContact(res.data));
  } catch (e) {
    yield put(errorContact());
  }
}

/*
  Alternatively you may use takeLatest.

  Does not allow concurrent fetches of user. If "USER_FETCH_REQUESTED" gets
  dispatched while a fetch is already pending, that pending fetch is cancelled
  and only the latest one will be run.
*/
function* careerWatcherSaga() {
  yield takeLatest(REQUEST_CONTACT, fetchContact);
}

export default careerWatcherSaga;
