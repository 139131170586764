import { Action, PayloadAction } from "@reduxjs/toolkit";
import {
  ERROR_SOLUTION,
  RECEIVED_SOLUTION,
  REQUEST_SOLUTION,
} from "./solution-constants";
import { ISolutionListType } from "./solution-contracts";

export const requestSolution = (): Action<typeof REQUEST_SOLUTION> => {
  return {
    type: REQUEST_SOLUTION,
  };
};

export const receivedSolution = (
  payload: ISolutionListType[]
): PayloadAction<ISolutionListType[], typeof RECEIVED_SOLUTION> => {
  return {
    type: RECEIVED_SOLUTION,
    payload,
  };
};

export const errorSolution = (): Action<typeof ERROR_SOLUTION> => {
  return {
    type: ERROR_SOLUTION,
  };
};

export type SolutionAction =
  | ReturnType<typeof requestSolution>
  | ReturnType<typeof receivedSolution>
  | ReturnType<typeof errorSolution>;
