import { InsightAction } from "./insight-actions";
import {
  INSIGHTS_NONE,
  INSIGHTS_RECEIVED,
  INSIGHTS_REQUESTING,
  RECEIVED_INSIGHTS,
  REQUEST_INSIGHTS,
} from "./insight-constants";
import { IInitialState } from "./insights-contracts";

const initialState: IInitialState = {
  list: null,
  status: INSIGHTS_NONE,
};

const InsightReducer = (state = initialState, action: InsightAction) => {
  switch (action.type) {
    case REQUEST_INSIGHTS: {
      return {
        ...initialState,
        status: INSIGHTS_REQUESTING,
      };
    }
    case RECEIVED_INSIGHTS: {
      return {
        list: action.payload,
        status: INSIGHTS_RECEIVED,
      };
    }
    default: {
      return state;
    }
  }
};

export default InsightReducer;
