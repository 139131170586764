import ReactGA from "react-ga4";

export function pageView(path?: string) {
  if (typeof path === "undefined") {
    ReactGA.send("pageview");
  } else {
    ReactGA.send({
      hitType: "pageview",
      page: path,
    });
  }
}
