import { call, put, takeLatest } from "redux-saga/effects";
import { getCompany } from "services/CompanyService";
import { errorCompany, receivedCompany } from "./company-actions";
import { REQUEST_COMPANY } from "./company-constants";

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* fetchCompany(): any {
  try {
    const res = yield call(getCompany);
    yield put(receivedCompany(res.data));
  } catch (e) {
    yield put(errorCompany());
  }
}

/*
  Alternatively you may use takeLatest.

  Does not allow concurrent fetches of user. If "USER_FETCH_REQUESTED" gets
  dispatched while a fetch is already pending, that pending fetch is cancelled
  and only the latest one will be run.
*/
function* careerWatcherSaga() {
  yield takeLatest(REQUEST_COMPANY, fetchCompany);
}

export default careerWatcherSaga;
