import { Box, Button, ButtonProps } from "@mui/material";
import { SECONDARY_COLOR } from "constants/color-constant";

interface IButtonProps extends ButtonProps {
  children: React.ReactNode;
}

const INButton = ({ children, style, ...props }: IButtonProps) => {
  return (
    <Button
      variant="contained"
      style={{
        background: SECONDARY_COLOR,
        color: "#fff",
        transition: "0.3s",
        textTransform: "uppercase",
        border: `1px solid ${SECONDARY_COLOR}`,
        borderRadius: "0",
        boxShadow: "unset",
        fontSize: "0.8rem",
        ...style,
      }}
      sx={{
        "&:hover": {
          background: "transparent !important",
          color: `${SECONDARY_COLOR} !important`,
        },
      }}
      {...props}
    >
      <Box
        style={{
          textDecoration: "none",
          display: "flex",
          alignItems: "center",
        }}
      >
        {children}
      </Box>
    </Button>
  );
};

export default INButton;
