import {
  Box,
  Divider,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  styled,
} from "@mui/material";
import Sciever from "assets/logo.png";
import Footer from "components/Footer";
import Details from "pages/contact/components/Details";
import sidebarContent from "pages/home/data/SidebarService";
import { NavLink, useLocation } from "react-router-dom";

const Bar = styled(Box)({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  background: "#f9f9f9",
  width: "250px",
});

const TopContent = styled(Box)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: 6,
  padding: "0 30px",
  position: "relative",
});

export const Toggler = styled(IconButton)({
  width: "50px",
  height: "50px",
  position: "absolute",
  top: "5px",
  right: "5px",
});

export const Logo = styled(Box)({});

export const Img = styled("img")({
  width: "60%",
});

const BottomContent = styled(Box)({
  flex: 4,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  paddingBottom: "5px",
});

interface ISiderbarProps {
  expand: boolean;
  setExpand: (e: boolean) => void;
  className?: string;
  tablet?: boolean;
}

const SidebarExpanded = ({
  tablet = false,
  expand,
  setExpand,
  className,
}: ISiderbarProps) => {
  const location = useLocation();

  const listItem = (el: {
    icon: React.ReactNode;
    activeIcon: React.ReactNode;
    label: string;
    route: string;
  }) => (
    <NavLink
      style={{
        color: "#000",
        textDecoration: "none",
      }}
      onClick={() => setExpand(!expand)}
      to={el?.route}
    >
      <ListItemButton
        sx={{
          "& .MuiListItemAvatar-root": {
            display: "flex",
            justifyContent: "center",
          },
          background:
            `/${location?.pathname?.split("/")[1]}` === el?.route
              ? "rgba(0, 0, 0, 0.04) !important"
              : "auto",
        }}
      >
        <ListItemAvatar>
          <IconButton
            sx={{
              "&:hover": {
                background: "unset",
              },
            }}
            size="medium"
          >
            {`/${location?.pathname?.split("/")[1]}` === el?.route
              ? el?.activeIcon
              : el?.icon}
          </IconButton>
        </ListItemAvatar>
        <ListItemText
          sx={{
            textTransform: "uppercase",
            "& .MuiTypography-root": {
              fontSize: "0.9rem",
              cursor: "pointer",
              marginTop: "2px",
              color: "#373737",
            },
            fontWeight:
              `/${location?.pathname?.split("/")[1]}` === el?.route
                ? "600"
                : "500",
          }}
          primary={el?.label}
        />
      </ListItemButton>
    </NavLink>
  );
  return (
    <Bar className={className} id="sidebar-tab">
      <TopContent>
        {/* <Toggler onClick={() => setExpand(!expand)} size="large">
          <IonIcon name="close-outline" />
        </Toggler> */}
        <Logo className="logo">
          <NavLink to="/" onClick={() => setExpand(!expand)}>
            <Img
              style={{
                width: "40%",
                maxWidth: "90px",
                display: "flex",
              }}
              src={Sciever}
            />
          </NavLink>
        </Logo>
      </TopContent>
      <BottomContent>
        <List
          id="side-menu"
          style={{
            width: "100%",
            padding: 0,
          }}
          sx={{
            "& .MuiListItemButton-root": {
              paddingBlock: "2px",
            },
          }}
        >
          {sidebarContent.map((el, i) => (
            <>
              {el?.label === "divider" ? (
                <Box width="80%" margin="0 auto">
                  <Divider />
                </Box>
              ) : (
                <>{listItem(el)}</>
              )}
            </>
          ))}
        </List>
        <Details />
        <Footer />
      </BottomContent>
    </Bar>
  );
};

export default SidebarExpanded;
