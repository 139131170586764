import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import sidebarContent from "pages/home/data/SidebarService";
import { NavLink, useLocation } from "react-router-dom";

export default function BottomNavbar() {
  const location = useLocation();

  return (
    <Box
      className="bottom_navbar"
      sx={{
        width: 500,
      }}
      style={{
        overflow: "hidden",
        position: "fixed",
        bottom: "0",
        width: "100%",
        backgroundColor: "#fff",
        zIndex: "1",
      }}
    >
      <Box
        display={"flex"}
        justifyContent="space-around"
        height={"4rem"}
        alignItems="center"
        style={{ backgroundColor: "#373737" }}
      >
        {sidebarContent.map((el, i) => (
          <NavLink
            style={{
              color: "#000",
              textDecoration: "none",
            }}
            to={el?.route}
          >
            <IconButton
              sx={{
                height: "40px",
                borderRadius: "0",
                color:
                  `/${location?.pathname?.split("/")[1]}` === el?.route
                    ? "#fff"
                    : "#cccc",
              }}
            >
              {`/${location?.pathname?.split("/")[1]}` === el?.route
                ? el?.activeIcon
                : el?.icon}
            </IconButton>
            <Box
              color={
                `/${location?.pathname?.split("/")[1]}` === el?.route
                  ? "#fff"
                  : "#cccc"
              }
              fontWeight={
                `/${location?.pathname?.split("/")[1]}` === el?.route
                  ? "600"
                  : "500"
              }
              fontSize="10px"
              display="flex"
              justifyContent={"center"}
            >
              <>{el.label}</>
            </Box>
          </NavLink>
        ))}
      </Box>
    </Box>
  );
}
