import { CareerAction } from "./career-actions";
import {
  CAREER_NONE,
  CAREER_RECEIVED,
  CAREER_REQUESTING,
  RECEIVED_CAREER,
  REQUEST_CAREER,
} from "./career-constants";
import { IInitialState } from "./career-contracts";

const initialState: IInitialState = {
  list: null,
  status: CAREER_NONE,
};

const CareerReducer = (state = initialState, action: CareerAction) => {
  switch (action.type) {
    case REQUEST_CAREER: {
      return {
        ...initialState,
        status: CAREER_REQUESTING,
      };
    }
    case RECEIVED_CAREER: {
      return {
        list: action.payload,
        status: CAREER_RECEIVED,
      };
    }
    default: {
      return state;
    }
  }
};

export default CareerReducer;
