import { Grid, styled } from "@mui/material";
import { Box } from "@mui/system";
import Footer from "components/Footer";
import BottomNavbar from "components/sidebar/BottomNavbar";
import SidebarExpanded from "components/sidebar/SidebarExpanded";
import Banner from "pages/home/components/Banner";
import { useState } from "react";
import { Outlet, useLocation } from "react-router";
import Router from "routes/routes";
import "./App.css";

const MainComponent = styled("div")({
  height: "100vh",
  minHeight: "100vh",
  overflowX: "hidden",
  overflowY: "auto",
  display: "flex",
});

const TopContent = styled("div")({
  width: "100%",
  minHeight: "60px",
  flex: 1,
  maxHeight: "60px",
  position: "absolute",
  top: 0,
  zIndex: 999,
});

const LeftContent = styled("div")({
  transition: "0.2s",
});

const Content = styled("div")({
  flex: "8",
  position: "relative",
});

const Body = styled("div")({
  height: "100%",
  overflowX: "hidden",
  overflowY: "auto",
});

function Main() {
  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = (status: boolean) => {
    setOpenDrawer(status);
  };

  const location = useLocation();

  const isHome = location?.pathname?.split("/")[1] === "";

  return (
    <MainComponent className="App">
      <LeftContent
        className="sidebar-desktop"
        style={{
          flex: 1,
          // maxWidth: "65px",
        }}
      >
        {/* <Sidebar expand={openDrawer} setExpand={setOpenDrawer} /> */}
        <SidebarExpanded expand={openDrawer} setExpand={setOpenDrawer} />
      </LeftContent>
      <Content className="content">
        {/* <TopContent className="sidebar-tablet">
          <SidebarTablet expand={openDrawer} setExpand={setOpenDrawer} />
        </TopContent> */}
        <Grid id="main-layout" overflow="auto" container spacing={3}>
          <Grid id="main-body" className="main-body" item lg={8}>
            <Body>
              <Box height={!isHome ? "calc(100% - 50px)" : "100%"}>
                <Outlet />
              </Box>
              <Box className="footer-device">
                <Footer />
              </Box>
            </Body>
          </Grid>
          <Grid lg={4} position="sticky" top="0" height="100%">
            <Banner />
          </Grid>
        </Grid>
      </Content>
      <BottomNavbar />
      {/* <Drawer open={openDrawer} onClose={() => toggleDrawer(false)}> */}

      {/* </Drawer> */}
    </MainComponent>
  );
}

export default Main;
