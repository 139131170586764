import NotFound from "components/404notfound";
import Main from "Main";
import React, { Suspense } from "react";
import { useRoutes } from "react-router-dom";
import Loader from "../components/Loader";

const Home = React.lazy(() => import("pages/home/Home"));
const Insights = React.lazy(() => import("pages/Insights/Insights"));
const Career = React.lazy(() => import("pages/career/Career"));
const Company = React.lazy(() => import("pages/company/Company"));
const Contact = React.lazy(() => import("pages/contact/Contact"));
const Works = React.lazy(() => import("pages/works/Works"));
const Portfolio = React.lazy(
  () => import("pages/works/components/portfolios/Portfolio")
);
const Products = React.lazy(
  () => import("pages/works/components/products/Products")
);

//Solutions

const Solutions = React.lazy(() => import("pages/solutions/Solutions"));

const SolutionDetail = React.lazy(
  () => import("pages/solutions/components/SolutionDetail")
);

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <Main />,
      children: [
        {
          index: true,
          element: <SuspenseComponent component={<Home />} />,
        },
        {
          path: "/insights",
          element: <SuspenseComponent component={<Insights />} />,
        },
        {
          path: "/career",
          element: <SuspenseComponent component={<Career />} />,
        },
        {
          path: "/company",
          element: <SuspenseComponent component={<Company />} />,
        },
        {
          path: "/contacts",
          element: <SuspenseComponent component={<Contact />} />,
        },
        {
          path: "/works",
          element: <SuspenseComponent component={<Works />} />,
        },
        {
          path: "/works/portfolio",
          element: <SuspenseComponent component={<Portfolio />} />,
        },
        {
          path: "/works/products",
          element: <SuspenseComponent component={<Products />} />,
        },
        {
          path: "/solutions",
          element: <SuspenseComponent component={<Solutions />} />,
        },
      ],
    },

    {
      path: "*",
      element: <NotFound />,
    },
  ]);
}

const SuspenseComponent = ({ component }: { component: React.ReactNode }) => (
  <Suspense fallback={<Loader />}>{component}</Suspense>
);
