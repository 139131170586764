import { Global } from "@emotion/react";
import { Box } from "@mui/material";
import Map from "pages/contact/components/Map";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { selectHomepageList } from "redux/modules/homeModule/homepage-selector";
import { SwiperSlide } from "swiper/react";
import INCarousel from "theme/carousel/INCarousel.component";
import BannerDetail from "./banner/BannerDetail";

const Banner = () => {
  const listBanner = useSelector(selectHomepageList);

  const location = useLocation();

  if (location?.pathname?.split("/")[1] === "contacts") {
    return <Map />;
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className="banner-section"
      style={{
        background: "#f9f9f9",
      }}
    >
      <Global
        styles={{
          ".banner-section .swiper-pagination-bullet": {
            border: "1px solid grey !important",
            opacity: "1 !important",
            background: "#bababa !important",
          },
          ".banner-section .swiper-pagination-bullet-active": {
            height: "7px !important",
            background: "#000 !important",
          },
        }}
      />

      <INCarousel spaceBetween={0} pagination={false}>
        {(listBanner?.banners ?? [])?.length > 1 &&
          listBanner?.banners.map((el, index) => (
            <SwiperSlide>
              <BannerDetail el={el} index={index} />
            </SwiperSlide>
          ))}
      </INCarousel>

      {/* <Map /> */}
    </Box>
  );
};

export default Banner;
