import { ISagaModule } from "redux-dynamic-modules-saga";
import { requestSolution } from "./solution-actions";
import { ISolutionAwareState } from "./solution-contracts";
import SolutionReducer from "./solution-reducers";
import solutionWatcherSaga from "./solution-saga";

export function getSolutionModule(): ISagaModule<ISolutionAwareState> {
  return {
    id: "solution-module",
    reducerMap: {
      solution: SolutionReducer,
    },
    sagas: [solutionWatcherSaga],
    // Actions to fire when this module is added/removed
    initialActions: [requestSolution()],
    // finalActions: [],
  };
}
