import { call, put, takeLatest } from "redux-saga/effects";
import { getInsights } from "services/InsightsService";
import { errorInsights, receivedInsights } from "./insight-actions";
import { REQUEST_INSIGHTS } from "./insight-constants";

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* fetchInsight(): any {
  try {
    const res = yield call(getInsights);
    yield put(receivedInsights(res.data));
  } catch (e) {
    yield put(errorInsights());
  }
}

/*
  Alternatively you may use takeLatest.

  Does not allow concurrent fetches of user. If "USER_FETCH_REQUESTED" gets
  dispatched while a fetch is already pending, that pending fetch is cancelled
  and only the latest one will be run.
*/
function* insightWatcherSaga() {
  yield takeLatest(REQUEST_INSIGHTS, fetchInsight);
}

export default insightWatcherSaga;
