import { createSelector } from "@reduxjs/toolkit";
import { IState } from "redux/interface/IState";
import { CONTACT_REQUESTING } from "./contact-constants";

const selectContacts = (state: IState) => state?.contact;

export const selectContactList = createSelector(
  selectContacts,
  (state) => state?.list?.[0]
);

export const selectContactsStatus = createSelector(
  selectContacts,
  (state) => state?.status
);

export const isContactsLoading = createSelector(
  selectContacts,
  (state) => state?.status === CONTACT_REQUESTING
);
