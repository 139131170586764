import LogoSciever from "../../assets/ScieverIncLogo.png";
export default function ScieverLogo() {
  return (
    //     <svg
    //       version="1.0"
    //       xmlns="http://www.w3.org/2000/svg"
    //       width="60"
    //       height="60"
    //       viewBox="0 0 1232.000000 1277.000000"
    //       preserveAspectRatio="xMidYMid meet"
    //     >
    //       <g
    //         transform="translate(0.000000,1277.000000) scale(0.100000,-0.100000)"
    //         fill="#000000"
    //         stroke="none"
    //       >
    //         <path
    //           d="M380 6640 l0 -5700 5700 0 5700 0 0 415 c0 362 -2 415 -15 415 -13 0
    // -15 -51 -15 -400 l0 -400 -3802 0 c-2091 0 -3799 2 -3797 4 2 2 51 39 108 82
    // 130 97 337 297 447 430 267 324 425 680 490 1099 23 153 24 478 0 640 -70 480
    // -278 898 -616 1235 -246 247 -586 492 -1050 760 -80 46 -408 217 -730 380
    // -797 405 -1034 536 -1224 679 -333 253 -518 494 -571 746 -25 117 -23 297 4
    // 408 82 342 382 643 756 760 255 80 604 88 904 21 372 -83 753 -302 1072 -616
    // 319 -314 542 -700 674 -1168 19 -70 75 -318 75 -335 0 -3 54 -5 120 -5 118 0
    // 120 0 120 23 0 12 -14 553 -30 1202 -16 649 -30 1226 -30 1283 l0 102 -90 0
    // -90 0 -6 -27 c-88 -397 -156 -491 -360 -501 -137 -6 -259 32 -594 188 -457
    // 213 -737 296 -1120 330 -167 16 -523 8 -670 -15 -497 -77 -936 -278 -1252
    // -573 -36 -34 -68 -62 -72 -62 -3 0 -6 961 -6 2135 l0 2135 5670 0 5670 0 0
    // -4455 c0 -3953 2 -4455 15 -4455 13 0 15 503 15 4470 l0 4470 -5700 0 -5700 0
    // 0 -5700z m306 -2005 c284 -226 743 -500 1344 -800 927 -464 1247 -663 1508
    // -940 184 -194 292 -392 337 -614 26 -127 17 -355 -19 -474 -93 -310 -338 -585
    // -660 -738 -56 -26 -141 -59 -190 -73 l-88 -26 -442 0 -443 0 -107 30 c-383
    // 108 -768 342 -1091 665 -141 141 -225 243 -341 416 l-84 125 0 1339 0 1338 84
    // -81 c46 -44 132 -119 192 -167z"
    //         />
    //         <path
    //           d="M6183 3565 c-58 -25 -71 -92 -27 -144 53 -63 154 -28 154 54 0 49
    // -15 74 -55 91 -40 16 -32 17 -72 -1z"
    //         />
    //         <path
    //           d="M9334 3555 c-10 -26 7 -35 66 -35 48 0 64 -5 87 -24 l28 -24 3 -466
    // c2 -312 -1 -479 -8 -506 -14 -51 -43 -70 -120 -77 -50 -5 -60 -9 -60 -24 0
    // -18 14 -19 275 -19 268 0 275 0 275 20 0 16 -7 20 -37 20 -21 0 -55 5 -75 11
    // -70 19 -69 8 -66 560 l3 491 24 19 c17 14 39 19 87 19 62 0 64 1 64 25 l0 25
    // -270 0 c-227 0 -272 -2 -276 -15z"
    //         />
    //         <path
    //           d="M10037 3115 c-27 -7 -71 -16 -98 -20 -43 -6 -49 -10 -49 -31 0 -21 5
    // -24 34 -24 19 0 44 -9 57 -19 l24 -19 0 -272 c0 -305 3 -294 -77 -307 -28 -4
    // -38 -10 -38 -24 0 -18 11 -19 180 -19 171 0 180 1 180 19 0 15 -10 21 -40 26
    // -71 12 -70 9 -70 271 l0 234 37 41 c101 112 239 128 307 36 20 -27 21 -44 24
    // -275 3 -291 3 -292 -76 -306 -40 -8 -52 -14 -52 -28 0 -17 13 -18 190 -18 179
    // 0 190 1 190 19 0 14 -10 20 -42 25 -79 13 -78 10 -78 267 0 266 -8 315 -57
    // 370 -90 100 -283 85 -398 -30 -22 -22 -43 -40 -47 -41 -5 0 -8 19 -9 43 -1 57
    // -12 92 -29 94 -8 1 -37 -5 -63 -12z"
    //         />
    //         <path
    //           d="M11057 3120 c-61 -16 -136 -59 -182 -105 -185 -184 -135 -523 93
    // -631 109 -51 276 -41 361 23 38 29 91 108 91 135 0 37 -35 18 -58 -32 -56
    // -119 -247 -148 -351 -54 -71 63 -109 200 -97 349 14 187 87 274 229 275 32 0
    // 67 -5 77 -10 11 -6 29 -37 41 -69 16 -46 28 -62 50 -71 69 -29 107 55 54 119
    // -49 57 -212 95 -308 71z"
    //         />
    //         <path
    //           d="M5687 3110 c-61 -16 -136 -59 -182 -105 -185 -184 -135 -523 93 -631
    // 109 -51 276 -41 361 23 38 29 91 108 91 135 0 37 -35 18 -58 -32 -56 -119
    // -247 -148 -351 -54 -71 63 -109 200 -97 349 14 187 87 274 229 275 32 0 67 -5
    // 77 -10 11 -6 29 -37 41 -69 16 -46 28 -62 50 -71 69 -29 107 55 54 119 -49 57
    // -212 95 -308 71z"
    //         />
    //         <path
    //           d="M6253 3110 c-19 -8 -59 -16 -180 -36 -23 -3 -33 -10 -33 -23 0 -13
    // 12 -20 52 -27 77 -13 78 -15 78 -310 0 -291 0 -292 -83 -301 -37 -5 -47 -9
    // -47 -24 0 -18 11 -19 195 -19 182 0 195 1 195 18 0 14 -12 20 -52 28 -41 7
    // -55 15 -65 34 -9 18 -13 110 -13 348 0 342 2 331 -47 312z"
    //         />
    //         <path
    //           d="M6707 3109 c-59 -14 -108 -43 -159 -96 -84 -86 -123 -216 -107 -352
    // 14 -124 95 -237 207 -289 76 -36 212 -39 283 -6 59 28 115 78 140 126 l19 38
    // -23 0 c-16 0 -33 -14 -55 -45 -37 -53 -95 -85 -167 -92 -167 -17 -255 104
    // -255 352 l0 95 251 0 251 0 -7 33 c-35 174 -201 278 -378 236z m160 -59 c41
    // -25 63 -68 63 -125 l0 -45 -170 0 -170 0 6 28 c13 56 47 113 82 137 48 32 141
    // 35 189 5z"
    //         />
    //         <path
    //           d="M8077 3109 c-59 -14 -108 -43 -159 -96 -84 -86 -123 -216 -107 -352
    // 14 -124 95 -237 207 -289 76 -36 212 -39 283 -6 59 28 115 78 140 126 l19 38
    // -23 0 c-16 0 -33 -14 -55 -45 -37 -53 -95 -85 -167 -92 -167 -17 -255 104
    // -255 352 l0 95 251 0 251 0 -7 33 c-35 174 -201 278 -378 236z m160 -59 c41
    // -25 63 -68 63 -125 l0 -45 -170 0 -170 0 6 28 c13 56 47 113 82 137 48 32 141
    // 35 189 5z"
    //         />
    //         <path
    //           d="M8650 3103 c-25 -8 -72 -20 -105 -26 -49 -11 -60 -17 -60 -32 0 -16
    // 9 -21 43 -26 76 -10 77 -11 80 -257 2 -119 1 -241 -3 -270 -6 -49 -10 -55 -41
    // -68 -18 -8 -45 -14 -59 -14 -18 0 -25 -5 -25 -20 0 -19 7 -20 195 -20 184 0
    // 195 1 195 19 0 14 -10 19 -45 24 -84 9 -85 12 -85 237 0 238 9 277 76 344 70
    // 70 112 74 138 13 14 -35 50 -67 73 -67 23 0 53 39 53 70 0 40 -51 97 -94 106
    // -73 13 -174 -41 -224 -120 l-28 -43 -12 75 c-7 42 -15 79 -19 82 -5 4 -28 1
    // -53 -7z"
    //         />
    //         <path
    //           d="M7040 3065 c0 -20 5 -25 23 -25 48 0 60 -21 237 -422 117 -266 123
    // -278 150 -278 26 0 33 13 163 299 155 342 175 377 223 397 23 10 34 21 34 34
    // 0 20 -5 20 -122 18 -115 -3 -123 -4 -123 -23 0 -14 8 -21 25 -23 35 -5 60 -30
    // 60 -61 0 -24 -191 -466 -209 -484 -6 -6 -193 397 -223 484 -13 39 3 59 48 59
    // 30 0 34 3 34 25 l0 25 -160 0 -160 0 0 -25z"
    //         />
    //         <path
    //           d="M11662 2704 c-131 -67 -94 -265 52 -281 115 -13 200 106 151 211 -35
    // 73 -131 106 -203 70z"
    //         />
    //         <path
    //           d="M9354 2064 c23 -23 23 -255 0 -278 -14 -15 -11 -16 31 -16 42 0 45 2
    // 31 15 -12 13 -15 42 -15 145 l0 130 38 0 c42 0 56 -11 66 -53 8 -32 -14 -73
    // -48 -88 -18 -8 -16 -9 13 -4 43 7 80 41 80 75 0 59 -54 90 -158 90 -49 0 -52
    // -1 -38 -16z"
    //         />
    //         <path
    //           d="M9605 2070 c14 -5 39 -50 78 -141 32 -74 61 -140 65 -146 5 -8 24 27
    // 52 95 65 161 80 190 98 195 9 3 -5 5 -33 5 -27 0 -42 -2 -32 -5 10 -3 17 -14
    // 17 -27 0 -21 -69 -198 -82 -211 -5 -5 -98 198 -98 215 0 5 8 14 18 19 13 8 2
    // 10 -43 10 -42 0 -54 -3 -40 -9z"
    //         />
    //         <path
    //           d="M9930 2053 c1 -24 2 -25 11 -10 7 12 23 17 54 17 l45 0 0 -129 c0
    // -104 -3 -133 -16 -145 -14 -15 -11 -16 31 -16 42 0 45 1 31 16 -13 12 -16 41
    // -16 145 l0 129 45 0 c31 0 47 -5 54 -17 9 -15 10 -14 11 10 l0 27 -125 0 -125
    // 0 0 -27z"
    //         />
    //         <path
    //           d="M10483 2069 c15 -9 17 -27 17 -144 0 -117 -2 -135 -17 -144 -13 -8
    // 13 -10 95 -11 61 0 112 4 112 9 0 5 3 18 7 28 7 17 6 17 -11 1 -13 -14 -34
    // -18 -82 -18 l-64 0 0 139 c0 137 0 140 23 144 12 2 -5 5 -38 5 -45 1 -56 -1
    // -42 -9z"
    //         />
    //         <path
    //           d="M10740 2053 c1 -24 2 -25 11 -10 7 12 23 17 54 17 l45 0 0 -129 c0
    // -104 -3 -133 -16 -145 -14 -15 -11 -16 31 -16 42 0 45 1 31 16 -13 12 -16 41
    // -16 145 l0 129 45 0 c31 0 47 -5 54 -17 9 -15 10 -14 11 10 l0 27 -125 0 -125
    // 0 0 -27z"
    //         />
    //         <path
    //           d="M11054 2064 c13 -12 16 -40 16 -139 0 -99 -3 -127 -16 -139 -14 -15
    // -9 -16 58 -15 40 1 64 3 53 6 -72 15 -65 -1 -65 148 l0 134 33 6 c79 15 147
    // -49 147 -138 0 -59 -21 -102 -63 -127 -25 -16 -28 -20 -12 -20 11 0 34 9 52
    // 20 77 46 91 165 27 231 -31 32 -94 49 -185 49 -55 0 -59 -1 -45 -16z"
    //         />
    //         <path
    //           d="M10247 1804 c-14 -14 -7 -35 11 -32 9 2 17 10 17 17 0 16 -18 25 -28
    // 15z"
    //         />
    //         <path
    //           d="M11397 1804 c-14 -14 -7 -35 11 -32 9 2 17 10 17 17 0 16 -18 25 -28
    // 15z"
    //         />
    //       </g>
    //     </svg>
    <img src={LogoSciever} alt="logo" width="60" />
  );
}
